@import "../node_modules/react-calendar/dist/Calendar.css";

.react-calendar {
  border: none;
  font-family: $font-family;
  width: auto;

  button {
    font-family: $font-family;
  }

  &__month-view__days__day {
    color: $default-color;

    &:disabled {
      color: $disabled-color;
    }
  }

  &__month-view__weekdays__weekday {
    abbr {
      text-decoration: none;
    }
  }

  &__navigation__arrow {
    border: 1px solid $disabled-color;
    font-size: 20px;
  }

  button.react-calendar__navigation__label {
    border: 1px solid $disabled-color;
    border-radius: 5px;
    margin: 0 10px;

    &:disabled {
      background-color: #ffffff;
      color: $default-color;
    }
  }

  &__navigation__label__labelText {
    font-size: 16px;
    font-weight: 600;

    @media ( min-width: 1024px ) {
      font-size: 18px;
    }
  }

  button.react-calendar__navigation__next-button,
  button.react-calendar__navigation__prev-button {
    border: 1px solid $disabled-color;
    border-radius: 5px;

    &:hover {
      background-color: #ffffff;
    }
  }

  &__tile {
    background: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px !important;

    @media ( min-width: 1024px ) {
      font-size: 16px;
    }

    &:disabled {
      background: none;
    }

    &:enabled:hover {
      background: $disabled-color;
    }
  }

  &__month-view__weekdays {
    font-size: 14px;
    font-weight: 500;
    text-transform: none;

    @media ( min-width: 1024px ) {
      font-size: 16px;
    }
  }
}
